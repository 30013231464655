//
// Gastro doc less
// --------------------------------------------------
// Styles directory
// 1. Global
// 1.1 Fonts
// 1.2 Colors
// 1.3 Elements
// 2. Frame
// 3. Navigation
// 4. Section 01 Welcome
// --------------------------------------------------

// 1. Global
// 1.1 Fonts
@font-face {
  font-family: 'orcfont';
  src: url('../assets/font/broken-detroit_pro_version.ttf');
}
h1, h2, h3 { font-family: 'orcfont', 'Arial', sans-serif; }
// 1.2 Colors
@global_bg_color: #756d5b;
@global_bg_color2: #bfbaad;
@footer_bg_color: #5f5a50;
@fg_color1: #756d5b;
@fg_color2: #bfbaad;
@navi_bg: #bfbaad;
// 1.3 Elements
@regular_text_size: 16px;
@large_panel_text_size: 16px;
@navi_height: 53px;
body {
	margin-top: @navi_height;
	font-family: 'Special Elite', cursive;
	font-size: @regular_text_size;
	background-color: @global_bg_color;
	// Verry Large grid
	@media (min-width: 1450px) {
		font-size: @large_panel_text_size;
	}	
}
section {
	padding-top: @navi_height;
	margin-top: -@navi_height;
}
p {
	line-height: 27px;
}
a {
	color: inherit;
	text-decoration: underline;
	&:hover {
		color: inherit;
	}
	&:active {
		color: inherit;
	}
	&:focus {
		color: inherit;
	}		
}
// 2. Frame
// 3. Navigation
.navbar.navbar-default {
	font-size: @large_panel_text_size;
	line-height: 0.429;
	background-color: @navi_bg;
	border-color: #756d5b;
	.navbar-brand {
		padding-left: 15px;
		padding-top: 2px;
		img {
			height: 46px;
		}
	}
	.navbar-toggle {
		margin-right: 15px;
	}
	li > a {
		color: @fg_color1;
		&:hover{
			color: @fg_color1;
			background-color: #c6c4be;
		} 
	}
	.navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus {
		background-color: #c6c4be;
	}
	.navbar-nav > li > a {
		padding-top: 16px;
		padding-bottom: 16px;
		padding-left: 16px;
		padding-right: 16px;
		&.facebook {
			padding-top: 19px;
			padding-bottom: 13px;
		}
	}
}
/****** This snippet centers the navibar!!!******/
.navbar .navbar-nav {
    display: inline-block;
    float: none;
	width: 100%;
	// Small grid
	@media (min-width: @screen-sm-min) {
		width: auto;
	}
	// Medium grid
	@media (min-width: @screen-md-min) {

	}
	// Large grid
	@media (min-width: @screen-lg-min) {

	}		
}
.navbar .navbar-collapse {
    text-align: center;
}
/****** End of: This snippet centers the navibar!!!******/
// 4. Section 01 Welcome
/* Section Welcome */
#welcome {
	position: relative;
	z-index: 100;
	#welcome_image_panel {
		position: relative;
		padding-left: 0px;
		padding-right: 0px;		
		height: 60vw;
		min-height: 0px;
		background-image: url(../assets/gfx/orc_intro_image.jpg);
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: 0 50px;
		background-size: cover;
		-moz-background-size: auto 73vw;
		-webkit-background-size: auto 73vw;
		-o-background-size: auto 73vw;
		background-size: auto 73vw;		
		// Small grid
		@media (min-width: @screen-sm-min) {
			height: 50vw;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			height: 100vh;
			min-height: 800px;
			background-position: center;
			-moz-background-size: cover;
			-webkit-background-size: cover;
			-o-background-size: cover;			
			background-size: cover;
		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}
		.hills_shape {
			position: absolute;
			bottom: -1px;
			left: 0px;	
			width: 100%;
			img {
				width: 100%;			
			}			
		}
		.welcome_slogan {
			position: absolute;
			top: 0px;
			left: 0px;				
			width: 100%;
			text-align: center;
			img {
				width: 70%;	
				margin-top: 10vw;
				// Small grid
				@media (min-width: @screen-sm-min) {
					width: 400px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					width: 500px;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					width: 636px;
				}				
			}				
		}
	}
}
/* Section About */
#about {
	position: relative;
	z-index: 900;	
	.row {
		background-image: url(../assets/gfx/about_bg_mobile.png);
		background-position: bottom center;
		background-size: 100%;
		background-repeat: no-repeat;
		padding-bottom: 100px;
		// Small grid
		@media (min-width: @screen-sm-min) {
			padding-bottom: 200px;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			background-image: url(../assets/gfx/about_bg.png);
		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}		
		#about_text_panel {
			h2 {
				width: 100%;
				text-align: center;
				margin-bottom: 36px;
			}
			p {
				width: 100%;
				text-align: center;
				color: @fg_color1;
				padding-left: 20px;
				padding-right: 20px;
				// Small grid
				@media (min-width: @screen-sm-min) {

				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					padding-left: 0;
					padding-right: 0;
				}				
			}
		}
		.orc_logo_panel {
			position: relative;
			text-align: center;
			// Small grid
			@media (min-width: @screen-sm-min) {

			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}
			// Large grid
			@media (min-width: @screen-lg-min) {
				text-align: left;
			}			
			img {
				width: 80%;
				margin-top: 25px;
				margin-bottom: 25px;
				// Small grid
				@media (min-width: @screen-sm-min) {
					width: 390px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					position: absolute;
					top: -13vw;
					margin-top: 0;
					margin-bottom: 0;
				}		
			}
		}
	}
}
/* Section Events */
#events {
	position: relative;
	z-index: 200;	
	row {
		position: relative;
	}
	svg {
		position: relative;
		left:0;
		width: 100%;
		path { fill: #d3d3d3; transition: .6s fill; }
		path:hover { fill: #eee; }
		a image { opacity: 0.9; transition: 1s opacity; }
		a:hover image { opacity: 1; }
		// Small grid
		@media (min-width: @screen-sm-min) {
			width:80vw;
			top:-10vw;
			left:-7vw;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}		
	}
	#events_images_panel {
		height: auto;
		// Small grid
		@media (min-width: @screen-sm-min) {
			height: 54vw;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {

		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}		
	}
	.eventsList {
		color: @fg_color2;
		h2 {
			text-align: center;
			width: 100%;
			margin-bottom: 25px;
		}
		p {
			text-align: center;
			width: 100%;
			margin-bottom: 25px;
		}
		// Small grid
		@media (min-width: @screen-sm-min) {
			padding-right: 25px;
			h2 {
				text-align: right;
				float: right;
				width: 100%;
			}	
			p {
				text-align: right;
				width: 100%;
			}			
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			
		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}		
	}
	.eventsInvitation {
		color: @fg_color2;
		text-align: center;
		position: relative;
		// Small grid
		@media (min-width: @screen-sm-min) {
			position: absolute;
			bottom: 4vw;
			right: 12vw;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			position: absolute;
			bottom: 5vw;
			right: 12vw;
		}
		// Large grid
		@media (min-width: @screen-lg-min) {
			position: absolute;
			bottom: 5vw;
			right: 12vw;			
		}		
	}
}
/* Section Impressions */
#impressionsOverview {
	position: relative;
	z-index: 800;
	.container-fluid {
		padding-left: 0px;
		padding-right: 0px;			
	}
	.impressions_top_shape {
		//font-size-hack to prevent space below the image on very small screens
		font-size: 1px;
		img {
			width: 100%;
		}
	}
	.impressions_bottom_shape_row {
		background-color: @footer_bg_color;
	}
	.impressions_bottom_shape {
		position: relative;
		top: -1px;
		font-size: 1px;
		img {
			width: 100%;
		}	
	}
	.impressions_header {
		background-color: @global_bg_color2;
		text-align: center;
		h2 {
			margin-top: 0;
			padding-top:20px;
			margin-bottom: 20px;
		}
		p {
			margin-bottom: 0;
			padding-bottom: 50px;
			color: @fg_color1;
		}
	}
	.impressions_list {
		background-color: @global_bg_color2;
		div {
			text-align: center;
		}	
		a {
			display: block;
		}
		a:hover {
			img.teaser {
			  transform: scale(1.03,1.03);
			  -webkit-transform: scale(1.03,1.03);
			}
		}
		img.teaser {
		  width: 90%;
		  -webkit-transition: all 0.5s;
		  transition: all 0.5s;  
		  margin-top: 15px;
		}
	}
}
/* Section Impressions */
#impressions {
	position: relative;
	z-index: 800;
	.container-fluid {
		padding-left: 0px;
		padding-right: 0px;			
	}
	.impressions_bottom_shape_row {
		background-color: @footer_bg_color;
	}
	.impressions_bottom_shape {
		position: relative;
		top: -1px;
		font-size: 1px;
		img {
			width: 100%;
		}	
	}
	.impressions_header {
		background-color: @global_bg_color2;
		text-align: center;
		div:first-child {
			display: inline-block;
			margin: 0 auto;
			position: relative;
			margin-top: 70px;
			a {
				position: absolute;
				left: -60px;
				top: 0;
			}
		}
		h2 {
			margin-top: 0;
			padding-top: 0;
			margin-bottom: 20px;		
		}
		p {
			margin-bottom: 0;
			padding-bottom: 50px;
			color: @fg_color1;
		}
	}
	.impressionsGallery {
		background-color: @global_bg_color2;
		.thumb {
		  padding: 2px;
		}		
		.thumb:hover {
		  z-index: 999;
		}
		img:hover {
		  transform: scale(1.03,1.03);
		  -webkit-transform: scale(1.03,1.03);
		}
		img {
		  width: 100%;
		  -webkit-transition: all 0.5s;
		  transition: all 0.5s;  		  
		}
	}
}
/* Section Contact */
#contact {
	color: @fg_color2;
	position: relative;
	z-index: 300;
	.contact_header {
		padding-top: 25px;
		h2 {
			width: 100%;
			text-align: center;
		}
		p {
			width: 100%;
			text-align: center;
		}
		.leftSide {
			text-align: center;
			margin-bottom: 0px;
				// Small grid
				@media (min-width: @screen-sm-min) {
					margin-bottom: 25px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}
				// Large grid
				@media (min-width: @screen-lg-min) {

				}			
			p {
				text-align: center;
				// Small grid
				@media (min-width: @screen-sm-min) {
					text-align: right;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}
		}
		.rightSide {
			text-align: center;
			margin-bottom: 25px;
			text-align: center;
			// Small grid
			@media (min-width: @screen-sm-min) {
				margin-bottom: 25px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}			
			p {
				text-align: center;
				// Small grid
				@media (min-width: @screen-sm-min) {
					text-align: left;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {

				}
				// Large grid
				@media (min-width: @screen-lg-min) {

				}
			}
		}
	}
	.impressum {
		background-color: @footer_bg_color;
		.footer {
			width: 100%;
			text-align: center;
			padding-top: 8px;
		}
		.impressum_block_1 {
			padding-left: 20px;
			padding-right: 20px;
		}
		.impressum_block_2 {
			padding-left: 20px;
			padding-right: 20px;
		}	
		// Small grid
		@media (min-width: @screen-sm-min) {
			.impressum_block_1 {
				padding-left: 20px;
				padding-right: 20px;
			}
			.impressum_block_2 {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			.impressum_block_1 {
				padding-left: 0;
				padding-right: 0;
			}
			.impressum_block_2 {
				padding-left: 0;
				padding-right: 0;
			}
		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}	
		#impressumContent {
			margin-top: 65px;
		}
	}
}
/* Section facebok */
#facebook {
	.termineRow {
		background-color: @footer_bg_color;
	}
	h2 {
		margin-top: 15px;
		margin-bottom: 50px;
		width: 100%;
		text-align: center;
	}
	.termine_bottom_shape {
		img {
			width: 100%;
		}
	}
}
/* rogue code added from ftp css */
#impressum h1, #impressum h3, #impressum h4, #impressum p, #impressum a,
#impressum ul li {
  color: #bfbaad;
}
#impressum h1 {
    margin-top: 50px;
}
// Small grid
@media (min-width: @screen-sm-min) {

}
// Medium grid
@media (min-width: @screen-md-min) {

}
// Large grid
@media (min-width: @screen-lg-min) {

}